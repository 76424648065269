import React from "react";

const LabeledText = ({ children, label }) => {
  return (
    <div>
      <h3 className="text-primary uppercase text-sm md:text-lg font-bold mb-2">
        {label}
      </h3>
      <p className="text-text-primary dark:text-text-primary-dark leading-6">
        {children}
      </p>
    </div>
  );
};

export default LabeledText;
