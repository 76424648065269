import React from "react";
import Section from "../components/common/Section";
import SectionHeading from "../components/common/SectionHeading";
import { StaticImage } from "gatsby-plugin-image";
import LabeledText from "../components/common/LabeledText";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../layouts";

const Project = () => {
  const { t } = useI18next();

  return (
    <Layout title="general.projectInformation">
      <Section className="pt-20">
        <SectionHeading as="h2">{t("project.information")}</SectionHeading>
        <StaticImage
          src="../static/images/project-logos-dark.png"
          objectFit="contain"
          className="!hidden dark:!flex mt-10 lg:w-3/4 rounded-lg"
        />
        <StaticImage
          src="../static/images/project-logos-light.png"
          objectFit="contain"
          className="flex dark:hidden mt-10 lg:w-3/4 rounded-lg"
        />
        <p className="text-text-primary dark:text-text-primary-dark leading-6 mt-4">
          {t("project.heading")}
        </p>
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-16 mt-10">
          <div className="flex flex-col gap-8 max-w-prose">
            <LabeledText label={t("project.title")}>
              {t("project.titleContent")}
            </LabeledText>
            <LabeledText label={t("project.objective")}>
              {t("project.objectiveContent")}
            </LabeledText>
            <LabeledText label={t("project.effects")}>
              {t("project.effectsContent")}
            </LabeledText>
            <LabeledText label={t("project.supportProgram")}>
              <strong>{t("project.NCBR")}</strong>
              <br />
              {t("project.supportProgramContent")}
            </LabeledText>
          </div>
          <div className="flex flex-col gap-8">
            <LabeledText label={t("project.value")}>
              PLN 5 498 756.60
            </LabeledText>
            <LabeledText label={t("project.contributionOfEU")}>
              PLN 3 699 609.92
            </LabeledText>
            <LabeledText label={t("project.implementationPeriod")}>
              01.06.2021 - 31.12.2023
            </LabeledText>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
